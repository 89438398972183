<template>
  <div class="position-relative">
    <defaultTemplate color="white" page="signin">
      <el-dialog
        :visible.sync="centerDialogVisible"
        :top="widthMax <= 1280 ? '10vh' : widthMax <= 1600 ? '3.5vh' : '15vh'"
        center
        v-if="centerDialogVisible"
        :close-on-click-modal="false"
        :show-close="false"
      >
        <template slot="title">
          <p class="mg-0 font-22" style="color: #353535">
            <span class="hidden-xs-only"
              >วิดีโอแนะนำระบบประเมินความพร้อมผู้ส่งออกไทย</span
            >
            <span class="hidden-sm-and-up"
              >วิดีโอแนะนำ<br />ระบบประเมินความพร้อมผู้ส่งออกไทย</span
            >
          </p>
        </template>
        <div class="text-center pd-b-7">
          <video :width="sizeVideo" height="auto" controls autoplay>
            <source
              :src="require('@/assets/exam/video/teaser.mp4')"
              type="video/mp4"
            />
          </video>
        </div>

        <div class="text-right">
          <span
            class="ai-center js-end font-22 mg-t-6 cr-pointer"
            @click="[(centerDialogVisible = false), setViewed()]"
            style="color: #959595"
          >
            <span class="mg-0 mg-r-7">ข้าม</span>
            <i class="fas fa-angle-double-right"></i>
          </span>
        </div>

        <!-- <button class="started" @click="closeModal">เริ่มต้นการใช้งาน</button> -->
      </el-dialog>
      <div class="text-white pd-5 main-slot">
        <Layout>
          <!-- <h1 class="text-center mg-t-0">เข้าสู่ระบบ</h1> -->
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            class="login-form"
          >
            <el-alert
              v-if="msgErrorValid !== ''"
              title="แจ้งเตือน"
              type="error"
              @close="msgErrorValid = ''"
              :description="msgErrorValid"
              :class="{ 'shake animated': animated }"
              class
              show-icon
            ></el-alert>
            <el-form-item label="อีเมล" prop="email">
              <el-input
                v-model="ruleForm.email"
                type="email"
                @keyup.enter.native="submitForm('ruleForm')"
              ></el-input>
            </el-form-item>
            <el-form-item label="รหัสผ่าน" prop="password">
              <el-input
                v-model="ruleForm.password"
                @input="validPass"
                show-password
                @keyup.enter.native="submitForm('ruleForm')"
                type="password"
                maxlength="30"
              ></el-input>
            </el-form-item>
            <div class="mg-b-4 text-right">
              <router-link
                to="/forgot-password"
                class="text-white not-hover font-22"
              >
                ลืมรหัสผ่าน?
              </router-link>
            </div>
            <el-form-item>
              <el-button
                class="w-100"
                type="danger font-22"
                @click="submitForm('ruleForm')"
                :loading="loading"
                >เข้าสู่ระบบ</el-button
              >
            </el-form-item>
          </el-form>
        </Layout>
        <div class="text-center text-grey text-white mg-t-1 font-22">
          คุณยังไม่มีบัญชี?
          <router-link
            :to="
              typeof this.$route.query.code != 'undefined'
                ? urlSignup
                : '/sign-up'
            "
            class="text-white not-hover"
            ><u>สมัครสมาชิก</u></router-link
          >
        </div>
        <p class="version">
          {{ version }}
        </p>
      </div>
    </defaultTemplate>
    <div @click="centerDialogVisible = true" class="play-video">
      <i class="fas fa-play"></i>
    </div>
    <el-dialog
      v-if="dialogCode"
      :visible.sync="dialogCode"
      center
      class="expDialog signUp"
      :class="resData.data == 'Is not time yet.' ? 'not-time' : ''"
    >
      <div class="is-flex ai-center mg-b-6">
        <p class="mg-0 mg-r-6 font-weight-bold">ชื่อโครงการ</p>
        <p class="mg-0" style="color: #9e9e9e">
          <span
            v-if="
              resData.data == 'not found data' ||
              resData.data == 'Refer code not work'
            "
            >ไม่พบชื่อโครงการ</span
          >
          <span v-else>
            {{ resData.obj.projectName }}
          </span>
        </p>
      </div>
      <div class="is-flex ai-center">
        <p class="mg-0 mg-r-6 font-weight-bold">วันที่จัดโครงการ</p>
        <p class="mg-0" style="color: #9e9e9e">
          <span
            v-if="
              resData.data == 'not found data' ||
              resData.data == 'Refer code not work'
            "
            >ไม่พบวันที่จัดโครงการ</span
          >
          <span v-else>
            {{
              moment(resData.obj.startDate)
                .add(543, "year")
                .format("D MMM yy") +
              " - " +
              moment(resData.obj.endDate).add(543, "year").format("D MMM yy")
            }}
          </span>
        </p>
      </div>
      <div slot="footer" class="text-left">
        <p
          v-if="resData.data == 'Refer code time out.'"
          class="cr-pointer"
          @click="dialogCode = false"
        >
          ปิดรับสมัครโครงการแล้ว
        </p>

        <p
          v-else-if="
            resData.data == 'not found data' ||
            resData.data == 'Refer code not work'
          "
          class="cr-pointer"
          @click="dialogCode = false"
        >
          ลิงก์ลงทะเบียนไม่ถูกต้อง
        </p>
        <p
          v-if="resData.data == 'Is not time yet.'"
          class="cr-pointer"
          @click="dialogCode = false"
        >
          ขณะนี้ยังไม่อยู่ในช่วงเปิดรับสมัครโครงการ
        </p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import defaultTemplate from "@/template/DefaultTemplate";
import Layout from "@/template/LayoutDefaultTemplate";
import { HTTP } from "@/service/axios";
import "@/service/Refer";
import "@/service/Company";
export default {
  props: ["routeParams"],
  data() {
    return {
      dialogCode: false,
      resData: null,
      urlSignup: "/sign-up?code=" + this.$route.query.code,
      projectCode:
        typeof this.$route.query.code != "undefined"
          ? this.$route.query.code
          : false,
      centerDialogVisible: true,
      version:
        "v" +
        process.env.VUE_APP_VERSION +
        " [" +
        process.env.VUE_APP_GIT_HASH +
        "]",
      sPhrase: process.env.VUE_APP_SECRET_PHRASE,
      ruleForm: {
        email: "",
        password: "",
      },
      rules: {
        email: [{ required: true, message: "กรุณากรอกอีเมล", trigger: "blur" }],
        password: [
          { required: true, message: "กรุณากรอกรหัสผ่าน", trigger: "blur" },
        ],
      },
      animated: true,
      loading: false,
      msgErrorValid: "",
      widthMax: window.innerWidth,
      sizeVideo: "100%",
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  mounted() {
    if (this.getCookie("videoIntro") === "viewed") {
      this.centerDialogVisible = false;
    }

    if (
      typeof this.routeParams != "undefined" &&
      this.routeParams.vdo == "no"
    ) {
      this.centerDialogVisible = false;
    }
    this.widthMax < 1380 && this.widthMax > 1140
      ? (this.sizeVideo = "60%")
      : this.widthMax < 16000 && this.widthMax > 1380
      ? (this.sizeVideo = "84%")
      : null;
    if (
      typeof this.token != "undefined" &&
      this.token != null &&
      this.token != ""
    ) {
      this.checkLogin(this.token, this.projectCode);
    } else if (typeof this.$route.query.code != "undefined") {
      this.checkReferCode(this.$route.query.code);
    }
  },
  methods: {
    async checkReferCode(code) {
      let res = await this.getReferCode(code);
      if (res.data.success) {
        this.project = res.data.obj;
        this.modalProject = true;
      } else {
        if (res.data.data == "Refer code time out.") {
          this.$router.push(this.$route.path);
        } else {
          //this.alertCatchError(res.data.data);
          this.$router.push(this.$route.path);
        }
        this.resData = res.data;
        this.dialogCode = true;
      }
    },
    setViewed() {
      let year = new Date().getFullYear() + 2;
      // console.log("year", year);
      document.cookie = `videoIntro=viewed; expires=Thu, 18 Dec ${year} 12:00:00 UTC; path=/`;
    },
    getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    validPass() {
      let arr = ["..%u2216", "..%c0%af", "%2e%2e%2f", "..%255c", "../", "..\\"];
      let reg = new RegExp(
        /..%u2216|..%c0%af|..%c1%9c|%2e%2e%2f|%2e%2e\/|..%2f|%2e%2e\\|..%5c|%252e%252e%255c|..%255c|\.\.\/|\.\/|\.\.\\|\.\\|\.\.|%00|%0|%2|%5|%%|%c|%d|%e|%f|%p|%u|%x|%s|%n|%\d{1,2}!s|%\d{1,2}!n\w+/g,
        "gi"
      );
      let index = reg.test(this.ruleForm.password);
      let matches = this.ruleForm.password.match(reg);
      if (index) {
        if (matches.length != null) {
          matches.map((data) => {
            this.ruleForm.password = this.ruleForm.password.replace(data, "");
          });
        }
        this.$message.error("ห้ามใช้อักขระพิเศษ");
      }
    },
    async submitForm(name) {
      try {
        HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
        this.loading = true;
        this.$refs[name].validate(async (valid) => {
          if (valid) {
            let tempPassword = this.CryptoJS.AES.encrypt(
              this.ruleForm.password,
              this.sPhrase
            ).toString();
            const signinAuth = {
              email: this.ruleForm.email,
              password: tempPassword,
              _csrf: this.$csrfToken,
            };

            let res = await this.signIn(signinAuth);
            if (res.data.success) {
              let userData = await this.getUserById(res.data.token);
              if (userData.data.success) {
                this.$store.commit(
                  "SET_USER",
                  JSON.parse(JSON.stringify(userData.data.obj))
                );
                this.$store.commit("SET_TOKEN", res.data.token);
                if (userData.data.obj.role == 0) {
                  let checkData = await this.checkCompanyData(
                    userData.data.obj
                  );
                  if (checkData) {
                    let url = "/edit-company";
                    typeof this.$route.query.code != "undefined"
                      ? (url = url + "?code=" + this.$route.query.code)
                      : null;
                    this.$router.push(url).catch((err) => {});
                  } else {
                    this.directUrl(
                      userData.data.obj,
                      typeof this.$route.query.code != "undefined"
                        ? this.$route.query.code
                        : ""
                    );
                  }
                } else {
                  this.directUrl(
                    userData.data.obj,
                    typeof this.$route.query.code != "undefined"
                      ? this.$route.query.code
                      : ""
                  );
                }
              }
            } else {
              if (res.data.data == "wrong email or password.") {
                this.$message({
                  showClose: true,
                  message: "รหัสผ่านไม่ถูกต้อง",
                  type: "error",
                });
              } else if (res.data.data == "not found email.") {
                this.$message({
                  showClose: true,
                  message: "ขออภัยกรุณาตรวจสอบอีเมลของท่านอีกครั้ง",
                  type: "error",
                });
              } else {
                this.$message({
                  showClose: true,
                  message: res.data.data,
                  type: "error",
                });
              }
              this.loading = false;
            }
          } else {
            this.loading = false;
          }
        });
      } catch {
        this.loading = false;
        console.log("Signin ERROR");
      }
    },
  },
  components: {
    defaultTemplate,
    Layout,
  },
};
</script>

<style scoped lang="scss">
.main-slot {
  min-height: calc(100vh - 402px);
}

@media (min-width: 1281px) {
  .main-slot {
    min-height: calc(100vh - 346px) !important;
  }
}

@media (min-width: 1024px) and (max-width: 1600px) {
  .main-slot {
    min-height: calc(100vh - 347px) !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .main-slot {
    min-height: calc(100vh - 346px) !important;
  }
}

@media (max-width: 767px) {
  .main-slot {
    min-height: calc(100vh - 355px) !important;
  }
  .play-video {
    bottom: 80px !important;
    width: 40px !important;
    height: 40px !important;
    i {
      font-size: 19px !important;
    }
  }
}

@media (max-width: 414px) {
  .main-slot {
    min-height: calc(100vh - 355px) !important;
  }
}

@media (max-width: 375px) {
  .main-slot {
    min-height: calc(100vh - 349px) !important;
  }
}

// @media (min-width: 1600px) {
//   .main-slot {
//     min-height: calc(100vh - 361px);
//   }
// }
.play-video {
  position: absolute;
  bottom: 30px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 998;
  cursor: pointer;
  i {
    color: #e92127;
    font-size: 30px;
    font-size: 25px;
    margin-left: 5px;
  }
}
</style>
